
import { computed, defineComponent, getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "BottomNav",

  setup() {
    const store = useStore();
    const { proxy }: any = getCurrentInstance();

    const active = ref(0);

    // created生命周期
    function myCreated() {
      switch (proxy.$route.path) {
        case "/nav/index":
          active.value = 0;
          break;
        case "/nav/cart":
          active.value = 1;
          break;
        default:
          break;
      }
    }

    // 购物车数量
    const cartCount = computed(() => {
      return store.getters.cartCount;
    });

    // 登出
    function logout() {
      window.sessionStorage.removeItem("token");
      proxy.$router.push("/login");
    }

    myCreated();

    return { active, cartCount, logout, myCreated };
  },
});
